import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import Button from "../../../components/shared/button";
import { StaticImage } from "gatsby-plugin-image"
import { slugify } from "@utils/functions";
import {
    HeroArea,
    HeroFiveInnerText,
    SubTitle,
    Title,
    HeightLightTitle,
    HeroTextDec,
    ButtonBox,
    HeroFiveCategory,
} from "./style";
const HeroFiveArea = () => {
    const HeroFiveCategoryQuery = useStaticQuery(graphql`
        query CategoryFiveQuery {
            categories: allCategoriesJson(limit: 9) {
                edges {
                    node {
                        name
                        color
                        image {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 260
                                    height: 110
                                    quality: 100
                                )
                            }
                        }
                    }
                }
            }
        }
    `);
    const { categories } = HeroFiveCategoryQuery;
    return (
        <HeroArea>
            <Container>
                <Row className="align-items-center">
                    <Col lg={7}>
                        <HeroFiveInnerText>
                            <SubTitle>Online</SubTitle>
                            <Title>
                                <HeightLightTitle> Community </HeightLightTitle>
                                Reporting Hate &amp; Resources Platform
                            </Title>
                            <HeroTextDec>
                                Community driven hate reporting tool to better understand hate incidents in greater Hamilton area
                            </HeroTextDec>
                            <ButtonBox>
                                <Button path="/report-incident" size="large">
                                    {" "}
                                    Report Hate Incident{" "}
                                    <i className="icofont-long-arrow-right"></i>
                                </Button>
                            </ButtonBox>
                        </HeroFiveInnerText>
                    </Col>
                    <Col lg={5}>
                    <StaticImage
                                        src="../../../data/images/smaller.svg"
                                        alt=""
                                    />
                    </Col>
                </Row>
            </Container>
        </HeroArea>
    );
};

export default HeroFiveArea;
