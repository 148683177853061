import styled, { device } from "@theme/utils";

export const ArchivePostInnerWrap = styled.div`
    margin-top: 60px;
    ${device.large} {
        margin-top: 0px;
    }
`;
export const ArchivePostHeader = styled.div``;
export const ArchivePostBox = styled.div``;
