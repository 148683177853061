import styled, { themeGet, device } from "@theme/utils";
export const PartnerWrap = styled.div`
    margin-top: 60px;
    padding: 60px 0;
    background-color: #edf0f8;
    ${device.medium} {
        margin-top: 80px;
    }
    ${device.large} {
        margin-top: 120px;
    }
`;
export const TrustedPartnersBox = styled.div`
    border-radius: 15px;
`;
export const SectionTitleThree = styled.div`
    margin-bottom: 20px;
    text-align: left;
`;
export const Title = styled.h6``;
